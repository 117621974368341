.scroll_score {
    height: auto;
    max-height: 350px;
    overflow-x: auto;
    padding: 0 10px;
    border-bottom: 1px solid #00ccff;
    box-shadow: inset 0 -10px 20px rgba(0, 166, 255, 0.1);
}

.scroll_score::-webkit-scrollbar {
    width: 3px;
    height: 1px;
}

/* Track */
.scroll_score::-webkit-scrollbar-track {
    background: #888888;
    border-radius: 10px;
}

/* Handle */
.scroll_score::-webkit-scrollbar-thumb {
    background: #ae00ff;
    border-radius: 10px;
}

.panel_border-line_score {
    border: 1px solid #545454;
    box-shadow: rgb(255, 255, 255, 0.2) 0px 0px 7px;
    color: #fff;
    backdrop-filter: blur(2px);
    display: grid;
    grid-template-columns: 10% 10% 70% 10%;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    border-radius: 5px;
}

.list-top-1 {
    border: 1px solid rgb(66, 200, 133);
    box-shadow: rgba(66, 200, 133, 0.5) 0px 0px 7px;
}

.list-top-2 {
    border: 1px solid #e5a419;
    box-shadow: rgba(197, 107, 3, 0.5) 0px 0px 7px;
}

.list-top-3 {
    border: 1px solid #aaaaaa;
    box-shadow: rgba(255, 255, 255, 0.5) 0px 0px 7px;
}

.normal-avatar {
    /* border: 1px solid #fff; */
}

.panel_border-line_score > .item-name {
    width: 100%;
    transition: 0.3s;
}

.top-tier-score {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 10px;
    margin-bottom: 10px;
    justify-content: center;
    animation: fadeup 2s;
}

.top-tier-score > .panel-top {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.focus_top_1 {
    /* animation: fx_focus_top_1 5s;
    animation-iteration-count: 999; */
    box-shadow: rgb(255, 136, 0) 0px 0px 1px 1px ,rgba(255, 213, 0, 1) 0px 0px 100px 5px;
}

.has-flag-top-1 {
    background-image: url("/public/images/img/flag-top-tier-1.png");
    background-size: contain;
    height: 230px;
    background-repeat: no-repeat;
    background-position: center;
}

.has-flag-top-2 {
    background-image: url("/public/images/img/flag-top-tier-2.png");
    background-size: contain;
    height: 180px;
    background-repeat: no-repeat;
    background-position: center;
    animation: flag_left 5s;
    animation-iteration-count: 999;
}

.has-flag-top-3 {
    background-image: url("/public/images/img/flag-top-tier-3.png");
    background-size: contain;
    height: 180px;
    background-repeat: no-repeat;
    background-position: center;
    animation: flag_right 5s;
    animation-iteration-count: 999;
}

.txt-name-comp {
    font-size: 20px;
    color: #46A2FF;
    text-shadow: 0px 0px 30px #42c8e2;
}

.btn-skip-loading {
    color: #fff;
    border: 1px solid #fff;
    width: 70px !important;
    height: 30px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 10px;
    font-size: 12px !important;
    cursor: pointer !important;
    pointer-events: all !important;
    position: absolute !important;
    z-index: 3;
    top: 5px;
    right: 5px;
}

.spin-loading-score {
    background-image: url("/public/images/loading-screen/score-loading.gif") !important;
    background-size: cover !important;
    background-position: center !important;
    max-width: 500px;
    width: 100% !important;
    height: 100dvh !important;

    top: 0 !important;
    inset-inline-start: unset !important;
    margin: 0 !important;
}

.ant-spin {
    display: flex !important;
    height: 100dvh !important;
    justify-content: center;
}

@keyframes fx_focus_top_1 {
    0% {box-shadow: rgb(89, 237, 163) 0px 0px 5px 3px, rgb(66, 200, 133) 0px 0px 50px 5px;}
    50% {box-shadow: unset;}
    100% {box-shadow: rgb(89, 237, 163) 0px 0px 5px 3px, rgb(66, 200, 133) 0px 0px 50px 10px;}
}

@keyframes flag_left {
    0% {transform: translateX(0);}
    50% {transform: translateX(5px);}
    100% {transform: translateX(0);}
}

@keyframes flag_right {
    0% {transform: translateX(0);}
    50% {transform: translateX(-5px);}
    100% {transform: translateX(0);}
}

@media only screen and (max-width: 450px) {
    .panel_border-line_score {
        grid-template-columns: 10% 12% 68% 10%;
        transition: 0.3s;
    }
}

@media only screen and (max-width: 400px) {
    .panel_border-line_score {
        grid-template-columns: 10% 13% 67% 10%;
        transition: 0.3s;
    }

    .panel_border-line_score > .item-name {
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.3s;
    }
}