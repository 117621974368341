@import url(//fonts.googleapis.com/css?family=REM);

.dragger_upload_custom_i_w{
    max-width: 200px !important; 
    max-height: 200px !important;
    width: 100%;
    height: 100%;
}

.dragger_upload_custom_i_w .ant-upload{
    padding: 0 !important;
    border-radius: 300px;
}

.dragger_upload_custom_i_h{
    /* max-width: 200px !important; 
    max-height: 200px !important; */
    width: 100%;
    height: 100%;
}

.dragger_upload_custom_i_m{
    max-width: 200px !important; 
    max-height: 200px !important;
    width: 100%;
    height: 100%;
}

.form_projectPix {
    margin: 0;
}

.form_projectPix:hover .projectPix_hover {
    visibility: unset;
}
  
.projectPix_hover {
    transition: background-color 0.3s ease-out;
    background-color: transparent;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 3;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectPix > img {
    object-fit: cover !important;
}

.icons_upload {
    color: #fff;
    font-size: 22px;
}

.projectPix {
    /* max-width: 200px !important; 
    max-height: 200px !important; */
    width: 100%;
    height: 100%;
}

.projectPix > img {
    object-fit: cover !important;
}

.btn-la {
    background-color: #dedede !important;
}

.layout-profile {
    background-color: #424141;
    height: 100dvh;
    display: flex;
    justify-content: center;
}

.panel-container-profile {
    width: 500px;
    height: 100dvh;
    background-color: #fff;
    background-size: cover;
}

.txt-profile {
    color: #fff;
}

.panel-detail {
    width: 100%;
    height: 100%;
    /* background-color: rgba(41, 61, 82, 0.7); */
    background: linear-gradient(140deg, rgba(11,198,255,0.1) 40%, rgba(0,0,0,0) 100%);
    border-radius: 5px;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    color: #fff;
    backdrop-filter: blur(20px);
}

.txt-point {
    font-size: 30px;
}

.panel-name {
    color: #fff;
    margin-top: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 5px;
    font-size: 16px;
}

.panel-container-profile {
    width: 500px;
    height: 100dvh;
    background-size: cover;
}

.fix-ribbon > .ant-ribbon-wrapper {
    height: 100%;
}

.flip-card {
    background-color: transparent;
    perspective: 1000px;
    height: 100%;
    max-height: 70%;
    animation: animationCARD 6s infinite;
    /* box-shadow: ; */
}

:where(.flip-card-front) .bar-card {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(52,52,52,1) 0%, rgba(24,24,24,1) 100%);
    border-radius: 15px 0 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

:where(.flip-card-back) .bar-card {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(52,52,52,1) 0%, rgba(24,24,24,1) 100%);
    border-radius: 0 15px 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

:where(.flip-card-front, .flip-card-back ) .detail-card {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgb(118, 118, 118) 0%, rgba(24,24,24,1) 100%);
}

.area-card-name {
    text-align: center;
}

.name-card-custom {
    color: transparent;
    font-size: 30px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.5);
    font-family: "Teko", sans-serif;
    text-transform: uppercase;
}

.xxe {
    color: #fff;
    font-size: 20px;
    letter-spacing: 15px;
    font-family: "REM", sans-serif;
    -webkit-text-stroke-width: 0.015rem;
    text-transform: uppercase;
}

:where(.flip-card-front) .xxe {
    transform: rotate(270deg);
}

:where(.flip-card-back) .xxe {
    transform: rotate(-270deg);
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 1s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 60dvh;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    display: grid;
    grid-template-columns: 15% 85%;
    background-color: transparent;
    border-radius: 15px 5px 5px 15px;
}

.flip-card-back {
    display: grid;
    grid-template-columns: 85% 15%;
    background-color: transparent;
    transform: rotateY(180deg);
    border-radius: 5px 15px 15px 5px;
}

:where(.flip-card-back) .detail-card {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px 0px 0px 15px;
}

:where(.flip-card-front) .detail-card {
    border-radius: 0px 15px 15px 0px;
}

.shadow-card {
    width: 80%;
    content: "";
    box-shadow: rgb(0 0 0) 25px 50px 50px 10px;
}


/* ////////////////////////////////////////////////// */

.layout-profile-unauth {
    background-color: #424141;
    height: 100dvh;
    display: flex;
    justify-content: center;
}

.panel-container-license {
    width: 500px;
    height: 100dvh;
    background-color: #bebebe;
}

:where(.panel-container-license) .panel-header {
    height: 7dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 15px 15px;
    border-bottom: 1px solid #fff;
    backdrop-filter: blur(5px);
    background-color: #424141;
}


:where(.panel-container-license) .panel-body {
    height: 67.9dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

:where(.panel-container-license) .panel-header .font-header {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 2px;
  }

.panel-full-h-100 {
    height: 92.9dvh !important;
}

@keyframes animationCARD {
    50% {transform: translateY(10px);}
}