.layout-check-in {
    background-color: #424141;
    height: 100dvh;
    display: flex;
    justify-content: center;
}

.body-check-in {
    height: 30.1dvh;
    display: flex;
    align-items: end;
}

.body-container-check-in {
    width: 500px;
    height: 100dvh;
    /* background-size: cover;
    background-image: url("../../../public/images/bg/BG.png"); */
    background-color: black;
    position: relative;
    overflow: hidden;
}

.body-panel-check-in {
  background-size: cover;
  background-image: url("../../../public/images/bg/BG.png");
  background-position: center;
  animation: bg-swap 8s linear infinite;
  transition: 3s;
}

@keyframes bg-swap {
  0% {
    background-position-y: 0;
  }
  50% {
    background-position-y: -30px;
  }
  100% {
    background-position-y: 0;
  }
}

.panel-time-stamp {
    padding-top: 30px;
}

#fx-panel {
    width: 500px;
    height: 100dvh;
    position: absolute;
    display: block;
    top: 0;
    z-index: 9;
}

.containers-circle-check {
  height: 100%;
  width: 95%;
  max-width: 200px;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .font-res {
  font-size: 18px;
} */

.wrapper {
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  /* background-color: red */
}

.main-element {
  position: absolute;
  top: 1%;
  left: 1%;
  display: block;
  height: 98%;
  width: 98%;
  margin: auto;
  align-self: center;
  z-index: 1;
  /* background-color: red */
}

.border_blue {
  position: absolute;
  display: block;
  top: -70%;
  left: -20%;
  z-index: 2;
  display: block;
  height: 300%;
  width: 150%;
  transform: rotate(220deg);
  overflow: hidden;
  background: linear-gradient(to right, transparent 20%, transparent 40%, #4ba5ff 41%, transparent 42%, transparent 44%, #acd6ff 44.5%, transparent 44.7%, transparent 100%);
  background-size: 200% auto;
  
  animation: shine 3s linear infinite;
}

.border_yellow {
  position: absolute;
  display: block;
  top: -50%;
  left: -10%;
  z-index: 2;
  display: block;
  height: 300%;
  width: 150%;
  transform: rotate(220deg);
  overflow: hidden;
  background: linear-gradient(to right, transparent 20%, transparent 40%, #ffe017 50%, transparent 60%, transparent 100%);
  background-size: 200% auto;
  
  animation: shine 3s linear infinite;
  animation-delay: 1s;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}


hr.thunder {
    border: unset;
    position: absolute;
    width: 500px;
    height: 100dvh;
    animation-name: thunder;
    animation-duration: var(--thunder-duration);
    animation-timing-function: linear;
    animation-delay: var(--thunder-delay);
    animation-iteration-count: infinite;
    z-index: 9;
  }
  
  hr:not(.thunder) {
    width: 50px;
    border-color: transparent;
    border-right-color: rgba(255, 255, 255, 0.5);
    border-right-width: 60px;
    position: absolute;
    bottom: 100%;
    transform-origin: 50% 50%;
    animation-name: rain;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    z-index: 9;
  }
  
  @keyframes rain {
    from {
      transform: rotate(90deg) translateX(0);
    }
    to {
      transform: rotate(90deg) translateX(calc(100vh + 20px));
    }
    /* from {
        transform: translateX(0);
      }
      to {
        transform: translateX(calc(100vh + 20px));
      } */
  }
  
  @keyframes thunder {
    0% {
      background-color: transparent;
    }
    1% {
      background-color: white;
    }
    2% {
      background-color: rgba(255, 255, 255, 0.8);
    }
    8% {
      background-color: transparent;
    }
  }

  .box_border_running {
    display: grid;
    /* place-content: center; */
    
    --border-angle: 0turn;
    --main-bg: conic-gradient(
        from var(--border-angle),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 5%,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0) 95%
      );
    
    border: solid 2px transparent;
    border-radius: 10;
    /* --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, transparent, rgb(255, 241, 81) 99%, transparent); */
    
    background: 
      var(--main-bg) padding-box,
      var(--gradient-border) border-box, 
      var(--main-bg) border-box;
    
    background-position: center center;

    animation: bg-spin 5s linear infinite;

    &.yellow {
      --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, transparent, rgb(255, 241, 81) 99%, transparent);
    }

    &.blue {
      --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, rgb(139, 51, 255) 40%, rgb(51, 170, 255) 50%, transparent);
    }
  }

  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }

  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  
