
.btn-reset-pwd {
    height: 65px;
    background-color: #E53D2A;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.btn-reset-pwd:hover {
    background-color: #F14E3B !important;
}

@media only screen and (max-width: 1500px) {
    .btn-reset-pwd {
        height: 55px;
        font-size: 18px;
    }
}


@keyframes fx_btn_reset_pwd {
    0% {box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px, rgb(21,107,215,1) 0px 0px 10px 5px;}
    50% {box-shadow: unset;}
    100% {box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px, rgb(21,107,215,1) 0px 0px 10px 5px;}
}