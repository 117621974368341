.form_regis {
    display: flex;
    padding-left: 360px;
    padding-right: 100px;
    align-items: center;
    height: 100%;
}

.left_colums_lgn {
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: column;
    z-index: 999;
    align-items: center;
    padding-right: 100px;
    justify-content: center;
    transition: 0.3s;
    background: linear-gradient(180deg, rgba(32,33,52,1) 35%, rgba(29,30,48,1) 100%);
    border-radius: 10px;
}

.right_colums_lgn {
    width: 50%;
    height: 100%;
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    background: radial-gradient(circle,  rgba(33,33,50,1) 0%, rgba(20,20,31,1) 60%);
}

.logoLogin{
    /* width: 130px !important; */
}

.card_in_right_colums_lgn {
    width: 60%;
    transform: translate(-5%, 0);
    /* background-color: transparent !important; */
    background: linear-gradient(180deg, rgba(255,187,65,1) 35%, rgba(255,145,23,1) 100%);
    /* box-shadow: none; */
}

.card_in_right_colums_lgn > .ant-card-body {
    padding: 50px;
}

.header_txt_custom {
    /* margin-top: 3rem; */
    font-size: 1.5rem;
}

#input_logi {
    background-color: transparent;
    border: 1px;
    border-bottom: 1px solid #919191 !important;
    color: #fff;
    border-radius: 0;
}
  
#input_logi::placeholder{
    color: #fff;
    opacity: 0.6;
    letter-spacing: 3px;
    transition: 0.1s;
}
  
#input_logi:focus{
    box-shadow: none !important;
}

.cus_input_pwd > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-input-password {
    background-color: transparent;
    border: 1px;
    border-bottom: 1px solid #919191 !important;
    border-radius: 0;
}
  
.cus_input_pwd > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-input-affix-wrapper-focused,
.cus_input_pwd > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-input-password:focus {
    box-shadow: none !important;
}

#input_logi_pwd {
    background-color: transparent;
    color: #fff;
}

#input_logi_pwd::placeholder{
    color: #fff;
    letter-spacing: 3px;
    opacity: 0.6;
    transition: 0.1s;
}

.cus_input_pwd > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-input-password > .ant-input-suffix > span > svg {
    color: #969696;
    opacity: 0.6;
}

.checkbox_custom:hover > .ant-checkbox > .ant-checkbox-inner {
    border: 1px solid #FF9823;
}

.checkbox_custom > .ant-checkbox > .ant-checkbox-inner {
    background-color: transparent;
    border: 1px solid #FF9823;
}

.checkbox_custom > .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: #FF9823 !important;
}

.segmented_custom {
    background-color: #14141f;
}

/* .segmented_custom > .ant-segmented-group{
    padding: 5px;
} */

.segmented_custom > .ant-segmented-group > .ant-segmented-item-selected {
    /* background-color: transparent !important; */
    /* background-color: red !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02);
    color: rgba(0, 0, 0, 0.88); */
}

.segmented_custom > .ant-segmented-group > .ant-segmented-item > .ant-segmented-item-label {
    padding: 0 5px !important;
}

.layout_regis {
    width: 27%;
    background-color: #fff;
    padding: 10px 50px;
    border-radius: 20px;
    box-shadow: 0px 0px 26.899999618530273px 0px #FFB144;
    animation: fadeup 1s;
}

@media screen and (max-width: 1800px) {
    .layout_regis {
        width: 25%;
    }
}

@media screen and (max-width: 1640px) {
    .layout_regis {
        width: 25%;
    }
}

@media screen and (max-width: 1500px) {
    .layout_regis {
        width: 30%;
    }
}

@media screen and (max-width: 1300px) {
    .layout_regis {
        width: 35%;
    }
}

@media screen and (max-width: 1100px) {
    .layout_regis {
        width: 45%;
    }
}

@media screen and (max-width: 850px) {
    .layout_regis {
        width: 55%;
    }
}

@media screen and (max-width: 750px) {
    .layout_regis {
        width: 60%;
    }
}

@media screen and (max-width: 650px) {
    .layout_regis {
        width: 65%;
    }
}

@media screen and (max-width: 550px) {
    .layout_regis {
        width: 70%;
    }
}

@media screen and (max-width: 480px) {
    .layout_regis {
        width: 90%;
    }
}

@media screen and (max-width: 380px) {
    .layout_regis {
        width: 95%;
    }
}