.panel-user-list {
    overflow-y: auto;
    margin-bottom: 10px;
    box-shadow: inset 0 -10px 10px rgba(0, 166, 255, 0.3);
    height: 400px;
    border-bottom: 1px solid #42c8e2;
    border-top: 2px solid rgba(57, 199, 255, 0.5);
    border-radius: 0 0 5px 5px;
    padding-top: 20px;
    border-style: dashed none inset none;
    transition: 0.3s;
}

.in-panel-user-list {
    display: flex;
    gap: 20px;
    /* margin-bottom: 30px; */
}

.panel-user-list::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}

/* Track */
.panel-user-list::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

/* Handle */
.panel-user-list::-webkit-scrollbar-thumb {
    background: #42c8e2;
    border-radius: 10px;
}

.card-user-panel {
    height: 350px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px 50px 10px 50px;
    border: 3px solid #c1c1c1;
    box-shadow: rgba(100, 229, 255, 0.3) 10px 10px 0px, rgb(100, 229, 255, 0.3) 5px 5px 5px;
    /* filter: grayscale(5); */
    transition: 0.3s;
    cursor: pointer;
}

.card-user-panel-selected {
    border: 3px solid #42c8e2;
    box-shadow: rgba(0, 166, 255, 0.5) 2px 2px 0px, rgba(0, 166, 255, 1) 3px 3px 10px;
    filter: grayscale(0) !important;
    transition: 0.3s;
    transform: translateY(-10px) !important;
    height: 370px;
    /* position: absolute; */
    /* z-index: 3; */
    /* left: 440px */
}

.card-user-panel:hover {
    filter: grayscale(0);
    transform: translateY(10px);
    transition: 0.3s;
}

.card-user-panel:hover > .card-user-vote {
    transition: 0.3s;
}

.card-user-panel-selected > .card-user-vote {
    border: 1px solid #84e9fd !important;
    background-color: rgba(93, 198, 255, 0.5) !important;
    transition: 0.3s;
}

.card-user-vote {
    width: 220px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: rgba(131, 131, 131, 0.9);
    backdrop-filter: blur(2px);
    border-radius: 10px 50px 10px 50px;
    border: 1px solid #9a9a9a;
    transition: 0.3s;
}

.card-user-detail {
    width: 170px;
}

.card-user-detail > .label-detail {
    opacity: 0.5;
    font-size: 12px;
}

.card-user-detail > .value-detail {
    font-size: 16px;
    margin-top: -5px;
}

.avatar-user-detail {
    box-shadow: rgb(0, 0, 0, 0.5) 0px 0px 20px;
}

.btn-vote {
    width: 100%;
    height: 50px;
    border-radius: 30px;
    border: 1px solid #84e9fd !important;
    background-color: transparent;
    color: #fff !important;
    font-size: 20px;
    text-transform: uppercase;
    box-shadow: rgb(19, 191, 217) 0px 0px 20px;
    transition: 0.3s;
}

.btn-vote:hover {
    background-color: #84e9fd;
    transition: 0.3s;
}