:root{
    --clr: white;
}

.indicator {
    background: white;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    margin-inline: auto;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    /* border-radius: 50px; */
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.indicator::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: transparent;
    left: -19px;
    bottom: 30px;
    border-bottom-right-radius: 20px;
    box-shadow: 4px 6px 0 1px var(--clr);
}

.indicator::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: transparent;
    right: -19px;
    bottom: 30px;
    border-bottom-left-radius: 20px;
    /* border-top-right-radius: 10px; */
    box-shadow: -4px 6px 0 1px var(--clr);
}

.icon-flag {
    background-color: #1495ff;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 60%;
    cursor: pointer;
    color: #fff;
    transition: 0.3s;
}

.icon-flag:hover {
    background-color: #44abff;
    transition: 0.3s;
}