.item-center > .panel-back {
    background-color: #262626;
    max-width: 130px;
    max-height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-20px);
    border-radius: 50px;
    /* border-radius: 5px; */
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 5px;
}

.item-center > .panel-back > .panel-btn {
    cursor: pointer;
    max-width: 80px;
    max-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    /* border-radius: 5px; */
    transition: 0.3s;
    padding: 20px;
    background: linear-gradient(270deg, rgba(47,215,254,1) 0%, rgba(83,55,255,1) 50%, rgba(252,51,255,1) 100%);;
}

/* theme */

.item-center > .panel-back > .panel-btn {
    background-color: #272625;
}

.item-center > .panel-back > .panel-btn:hover {
    background-color: #272625;
    transition: 0.3s;
}

.menu-custom-grid {
    display: grid;
    /* grid-template-columns: auto auto auto auto auto; */
    grid-template-columns: 19% 19% 24% 19% 19%;
    background-color: #262626;
}

.item-menu {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}