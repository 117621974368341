@import url(//fonts.googleapis.com/css?family=Prompt);

*{
  font-family: "Prompt", sans-serif;
}

body {
  font-family: "Prompt", sans-serif;
  margin: 0;
}

.form-non-txt > .ant-form-item-row > .ant-form-item-label {
  display: none;
}

.bg-top-announcement {
  display: none !important;
}

.css-i4bv87-MuiSvgIcon-root {
  height: unset !important;
}

.layout-main {
  background-color: #424141;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-main > .center-panel {
  width: 500px;
  height: 100%;
  /* background: radial-gradient(circle, rgba(255,223,187,1) 0%, rgba(255,172,124,1) 80%, rgba(255,172,124,1) 82%); */
  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(38,26,9,1) 35%, rgba(235,166,3,0.6895133053221288) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_header_list > .ant-card-body {
  padding: 0 !important;
  height: 100%;
}

:where(.body-container-check-in, .panel-container-profile, .body-container-site) .panel-header {
  height: 7dvh;
  display: grid;
  /* grid-template-columns: 70% 30%; */
  grid-template-columns: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* border-radius: 0 0 15px 15px; */
  /* border-bottom: 1px solid #fff; */
  /* backdrop-filter: blur(5px); */
  z-index: 10;
  position: relative;
  /* width: 500px; */
}

.layout-circle-check-in {
  position: relative;
  z-index: 10;
}

.body-layout {
  width: 500px;
  height: 100dvh;
  background-color: black;
}

:where(.body-layout) .body-container-page > .panel-body {
  height: 82.1dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-size: cover;
  background-image: url("../public/images/bg/BG.png");
}

:where(.body-layout) .panel-menu {
  height: 8dvh;
  background-color: white;
  display: grid;
  position: relative;
  z-index: 10;
}

/* =============================================================== */

:where(.body-container-check-in, .panel-container-profile, .body-container-site) .panel-body {
  height: 67.9dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

:where(.body-container-check-in, .panel-container-profile, .body-container-site) .panel-footer {
  height: 17dvh;
}

:where(.body-container-check-in, .panel-container-profile, .body-container-site) .panel-menu {
  height: 8dvh;
  background-color: white;
  display: grid;
  position: relative;
  z-index: 10;
}

:where(.body-container-check-in, .panel-container-profile) .panel-header .font-header {
  /* text-align: center; */
  font-size: 26px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 2px;
}

.panel-full-h {
  height: 84.9dvh !important;
}

:where(.body-container-check-in, .panel-container-profile, .body-container-site) .panel-body .panel-txt-header {
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px;
  animation: fadefromleft 1s;
}

.layout-site {
  background-color: #424141;
  height: 100dvh;
  display: flex;
  justify-content: center;
}

.container-site-config {
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #424141;
}

.body-container-site {
  width: 500px;
  height: 100dvh;
  background-color: #848484;
}

.ant-input {
  font-family: "Prompt", sans-serif !important;
}

.custom-scroll {
  overflow-y: auto;
}

.custom-spin-dot {
  width: 100% !important;
  color: #fff !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  backdrop-filter: blur(5px) !important;
}

.spin-loading-site {
  background-image: url("../public/images/loading-screen/loading-nx.gif") !important;
  background-size: cover !important;
  background-position: center !important;
  max-width: 500px;
  width: 100% !important;
  height: 100dvh !important;

  top: 0 !important;
  inset-inline-start: unset !important;
  margin: 0 !important;
}

/* .ant-spin-fullscreen {
  background-image: url("../public/images/loading-screen/loading-nx.gif");
  background-size: cover !important;
  background-position: center !important;
} */

/* .spin-loading-score-board {
  background-image: url("../public/images/loading-screen/score-loading.gif") !important;
  background-size: cover !important;
  background-position: center !important;
} */

#scroll-container {
  overflow: hidden;
}

#scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  
  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #46A2FF;
  box-shadow: 1px 0px 10px #5517ff;
  outline: 0;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: #6eb6ffa1;
  border-color: #46A2FF;
}

.ant-pagination .ant-pagination-item-active a {
  color: #fff !important;
  font-weight: 200;
}

.ant-pagination .ant-pagination-item-active:hover {
  border-color: #46A2FF;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #293D52;
  border: 1px solid #46A2FF;
}

.ant-table-wrapper .ant-table-tbody >tr >td {
  padding: 3px 0;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #fff;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link, .ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link > .anticon,.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link > .anticon{
  color: #fff;
}

.ant-pagination .ant-pagination-item a {
  color: #fff;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector > .ant-select-selection-item {
  color: #fff;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector > .ant-select-selection-placeholder {
  color: #fff;
}

.ant-select:not(.ant-select-customize-input) .ant-select-clear {
  color: #46A2FF;
  background-color: transparent;
}

.ant-select:not(.ant-select-customize-input) .ant-select-arrow {
  color: #46A2FF;
  background-color: transparent;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #46A2FF;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #46A2FF;
  color: #fff;
}

.ant-table-wrapper .ant-table-thead {
  display: none !important;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters {
  background-color: #2C2F48 !important;
  color: #fff;
}

.ant-table-wrapper .ant-table-column-sorter-up.active, .ant-table-wrapper .ant-table-column-sorter-down.active {
  color: #46A2FF;
}

:where(.css-dev-only-do-not-override-1n3s8bl).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-1n3s8bl).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #46A2FF;
}

.ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
  border-bottom: none;
}

:where(.body-container-page).ant-table-wrapper .ant-table-tbody >tr >th, .ant-table-wrapper .ant-table-tbody >tr >td {
  border-bottom: none;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover {
  background: transparent;
}

.ant-spin-nested-loading .ant-spin-container::after {
  background: transparent !important;
}

.ant-spin .ant-spin-dot-item {
  background-color: #46A2FF;
  box-shadow: rgba(70, 162, 255, 1) 0px 7px 29px 1px;
}

.layout-page {
  background-color: #424141;
  height: 100dvh;
  display: flex;
  justify-content: center;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #46A2FF;
  background-color: #46A2FF;
}

.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #46A2FF;
}

.ant-picker-focused.ant-picker {
  border-color: #46A2FF;
  box-shadow: 1px 0px 10px #5517ff;
}

.ant-picker , .ant-picker:hover {
  background: #293D52;
  border: 1px solid #46A2FF;
}

.ant-picker > .ant-picker-input > input {
  color: #fff;
}

.ant-picker > .ant-picker-input > input::placeholder {
  color: #fff;
} 

.ant-picker > .ant-picker-input > .ant-picker-suffix {
 color: #46A2FF; 
}

.ant-input, .ant-input:hover, .ant-input:focus  {
  background: #293D52;
  border: 1px solid #46A2FF;
  color: #fff;
}

.ant-input::placeholder {
  color: #fff;
}

.ant-drawer .ant-drawer-header {
  background: linear-gradient(38deg, rgba(255,176,15,1) 0%, rgba(40,55,84,1) 20%);
  box-shadow: 0px 1px 10px rgba(0,0,0,0.3);
  position: relative;
}

.ant-drawer .ant-drawer-body {
  padding: 0;
  background-color: #1F3144;
}

.ant-drawer-close > .anticon-close {
  color: #fff;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from { -moz-transform: translateX(100%); }
  to { -moz-transform: translateX(-100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from { -webkit-transform: translateX(100%); }
  to { -webkit-transform: translateX(-100%); }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes fadeup {
  from {
      opacity:0;
      transform: translateY(30px);
  }
  to {
      opacity:1;
      transform: translateY(0);
  }
}

@keyframes fadefromleft {
  from {
      opacity:0;
      transform: translateX(-30px);
  }
  to {
      opacity:1;
      transform: translateX(0);
  }
}

@keyframes fx_btn_add_list {
  0% {box-shadow: unset;}
  50% {box-shadow: rgb(255, 255, 255) 0px 0px 0px 5px, rgb(4, 119, 226) 0px 0px 0px 6px;}
  100% {box-shadow: unset;}
}

@keyframes rotateFX {
  50% {transform: rotate(180deg);}
}

@media only screen and (max-width: 500px) {
  .layout-circle-check-in {
      width: 100%;
  }

  .font-res {
      font-size: 1.2rem;
  }

  .curren-time {
      margin-top: -20px;
  }
}

@media only screen and (max-width: 420px) {
  .font-res {
      font-size: 1.1rem;
  }

  .curren-time {
      margin-top: -10px;
      font-size: 70px;
  }
}

@keyframes animationBG {
  50% {background-position: center;}
}

/* #f5f5f5 */