.body-container-site {
    background-image: url("https://i.pinimg.com/564x/c4/33/36/c43336eb7f0805d52bf89ff40e752f13.jpg");
}

:where(.body-container-site) .panel-header {
    background-color: #fff;
}

:where(.body-container-site) .panel-header .font-header {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: #000;
    letter-spacing: 2px;
}

.leave-menu {
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 15px 25px;
    transition: 0.3s;
}

.leave-menu:hover {
    background-color: rgb(174, 174, 174);
    transition: 0.3s;
}