
#container-page-bg {
    background-color: #424141;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100dvh;
}

.panel-form {
    width: 500px;
    background-image: url("../../../../public/images/bg/BG.png");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline-start: 0 30px;
    animation: fade 2s;
    height: 100%;
}

.inline-panel-form {
    width: 550px;
    height: 100%;
}

.font-size16{
    font-size: 16px !important;
}

.font-size18{
    font-size: 18px !important;
}

.txt-header {
    font-size: 50px;
    color: #49494A;
    font-weight: 700;
    line-height: 66px;
}

.sub-txt-header {
    font-size: 18px;
    color: #7E8B9E;
}

.txt-hover {
    color: #B8B8B8;
    transition: 0.3s;
    cursor: pointer;
}

.txt-hover:hover {
    color: #E14D56;
    transition: 0.3s;
}

.forgot-pwd-txt {
    transition: 0.3s;
    cursor: pointer;
    font-size: 18px;
    color: #E14D56;
}


.btn-sign-in {
    height: 50px;
    background: linear-gradient(270deg, rgba(47,215,254,1) 0%, rgba(83,55,255,1) 50%, rgba(252,51,255,1) 100%);
    /* background-color: #ff7946; */
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}

.btn-sign-in:hover {
    background-color: #ff7946 !important;
}

.custom-divider-lgn {
    margin: 25px 0 !important;
}

.custom-divider-lgn.ant-divider-with-text::before {
    border-block-start: 1px solid #7E8B9E !important;
    transform: translateY(0%) !important;
}

.custom-divider-lgn.ant-divider-with-text::after {
    border-block-start: 1px solid #7E8B9E !important;
    transform: translateY(0%) !important;
}

.btn-lgn-social {
    width: 100%;
    height: 65px;
    box-shadow: unset !important;
    color: #7E8B9E;
    transition: 0.3s;
}

.btn-lgn-social:hover {
    border: 1px solid #E14D56 !important;
    color: #E14D56 !important;
    transition: 0.3s;
}

.form_auth {
    animation: faderight 1s;
}

.logo_pdi {
    width: 185px;
}

.mb-10px{
    margin-bottom: 10px !important;
}

.mb-30px{
    margin-bottom: 30px !important;
}

.mt-30px{
    margin-top: 30px;
}

.mt-20px{
    margin-top: 20px;
}

.mt-5rem{
    margin-top: 5rem;
}

.input-h45{
    height: 45px;
}

.custom_to_input {
    color: white;
    padding-left: 20px;
}

.custom_to_input > input {
    background-color: #293D52;
    color: white;
}

.custom_to_input > input::placeholder {
    color: rgb(86, 110, 137);
}

.custom_to_input > .ant-input-suffix > .ant-input-password-icon {
    color: rgb(86, 110, 137);
}

.custom_to_input > .ant-input-suffix > .ant-input-password-icon:hover {
    color: rgb(108, 139, 174);
}

.custom_to_input::placeholder {
    color: rgb(86, 110, 137);
}

@media only screen and (max-width: 1500px) {
    .inline-panel-form {
        width: 480px;
    }

    .logo_pdi {
        width: 155px;
    }

    .txt-header {
        font-size: 43px;
    }

    .mt-30px{
        margin-top: 20px;
    }
    
    .mt-20px{
        margin-top: 15px;
    }

    .mt-5rem{
        margin-top: 3.5rem;
    }
    
    .input-h45{
        height: 40px;
    }

    .btn-sign-in {
        height: 55px;
        font-size: 18px;
    }

    .btn-lgn-social {
        height: 55px;
    }

    .font-size16{
        font-size: 14px !important;
    }
    
    .font-size18{
        font-size: 16px !important;
    }

    .forgot-pwd-txt {
        font-size: 16px;
    }

    .custom-divider-lgn {
        margin: 17px 0 !important;
    }

    .mb-30px{
        margin-bottom: 20px !important;
    }
}

@media only screen and (max-width: 500px) {
    #container-page-bg {
        height: 100dvh;
    }

    .panel-form {
        width: 500px;
    }
}

@keyframes fade {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

@keyframes faderight {
    from {
        opacity:0;
        transform: translateX(30px);
    }
    to {
        opacity:1;
        transform: translateX(0);
    }
}

@keyframes fadeup {
    from {
        opacity:0;
        transform: translateY(30px);
    }
    to {
        opacity:1;
        transform: translateY(0);
    }
}

@keyframes fadedown {
    from {
        opacity:0;
        transform: translateY(-30px);
    }
    to {
        opacity:1;
        transform: translateY(0);
    }
}

@keyframes fadepopup {
    from {
        opacity:0;
        transform: scale(50px, 50px);
    }
    to {
        opacity:1;
        transform: scale(100px, 100px);
    }
}

