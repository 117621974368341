.custom-menu{
    width: 100%;
    height: 85vh;
    padding: 0 5%;
    overflow-y: auto;
}

.custom-menu-item {
    /* height: 130px; */
    border-radius: 10px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    transition: 0.3s;
    cursor: pointer;
    padding: 10px 10px;
    /* background-color: red; */
}

.false-menu {
    cursor: not-allowed !important;
}

.false-menu > div > .menu-icon > img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.custom-menu-item > div > .menu-icon  {
    transition: 0.3s;
    transform: translate(0px, 0px);
}

.custom-menu-item:hover > div > .menu-icon  {
    transition: 0.3s;
    transform: translate(0px, -10px);
}

.menu-icon {
    /* margin-top: 1rem; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-menu-item:hover > div > .menu-txt  {
    transition: 0.3s;
    color: #fff;
}

.menu-txt {
    /* margin: 2rem 0; */
    /* margin-top: 1.5rem; */
    text-align: center;
    /* font-size: 1rem; */
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    transition: 0.3s;
}

.sider_custom > ul {
    background-color: transparent !important;
}

.custom-menu::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}

/* Track */
.custom-menu::-webkit-scrollbar-track {
    background: #888;
    border-radius: 10px;
}

/* Handle */
.custom-menu::-webkit-scrollbar-thumb {
    background: #ff8d00;
    border-radius: 10px;
}

/*=================================================== COLLAPE ===================================================*/

.menu-sm {
    padding: 0 10%;
}

.item-sm {
    height: 70px !important;
}

.txt-sm {
    margin-top: 1rem !important;
    font-size: 0.6rem !important;
    /* display: none; */
}

.custom_menu {
    background-color: transparent;
}

.custom_menu li {
    color: #fff;
}

.custom_menu li:hover {
    color: #fff;
}

.ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: #fff !important;
    background-color: #0B93FF !important;
}

.ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: #fff !important;
    background-color: #0B93FF !important;
}

.ant-menu-item-selected {
    background-color: #0076D1 !important;
    color: #fff !important;
}

.ant-menu-item-selected:hover {
    background-color: #0076D1 !important;
    color: #fff !important;
}

.ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover {
    color: #fff !important;
}

.ant-menu-sub > li {
    padding-left: 30px !important;
}

/* /////////////////// JOB SIDER ///////////////////*/

.menu_status {
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
    transition-timing-function: linear;
}

.menu_job {
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
}

.menu_job_checked {
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px;
    border-radius: 10px;
    transition: 0.3s;
}
/* 
.menu_job:hover {
    color: #FFF391;
    transition: 0.3s;
} */

.aimation_test {
 animation: fadeup_test 6s;
 animation-iteration-count: 999;
}

.aimation_test_2 {
    animation: fadeup_test_2 6s;
    animation-iteration-count: 999;
}

.scroll_job {
    height: auto;
    max-height: 400px;
    overflow: auto;
    /* display: flex; */
    /* flex-direction: column-reverse; */
}


.scroll_job::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.scroll_job::-webkit-scrollbar-track {
    background: #dedede;
    border-radius: 10px;
}

/* Handle */
.scroll_job::-webkit-scrollbar-thumb {
    background: #91d5ff;
    border-radius: 10px;
}

/* Handle on hover */
.scroll_job::-webkit-scrollbar-thumb:hover {
    background: #95d5fc; 
}

@keyframes fadeup_test {
    from {
        opacity:0;
        transform: translateY(30px);
    }
    to {
        opacity:1;
        transform: translateY(0);
    }
}

@keyframes fadeup_test_2 {
    from {
        opacity:0;
        transform: translateY(-30px);
    }
    to {
        opacity:1;
        transform: translateY(0);
    }
}

/* ////////////////////////////////////////////////*/