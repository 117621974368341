.layout-market {
    background-color: #424141;
    height: 100dvh;
    display: flex;
    justify-content: center;
}

.body-container-market {
    width: 500px;
    height: 100dvh;
    background-color: #fff;
}

:where(.body-container-market) .panel-header {
    height: 7dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 15px 15px;
    border-bottom: 1px solid #fff;
    backdrop-filter: blur(5px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: #272625;
}

:where(.body-container-market) .panel-header .font-header {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 2px;
}

:where(.body-container-market) .panel-body {
    height: 67.9dvh;
    overflow: auto;
}

:where(.body-container-market) .panel-footer {
    height: 17dvh;
}

:where(.body-container-market) .panel-menu {
    height: 8dvh;
    background-color: white;
    display: grid;
}

:where(.body-container-market) .panel-body .panel-txt-header {
    color: #272625;
    /* font-size: 30px; */
    margin-bottom: 10px;
    animation: fadefromleft 1s;
}

.custom-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
    background: #888;
    border-radius: 10px;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
    background: #272625;
    border-radius: 10px;
}

:where(.body-container-market) .panel-menu .menu-custom-center > .panel-back > .panel-btn {
    background-color: #272625;
}

:where(.body-container-market) .theme-panel .theme-items {
    background-color: #999999;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

:where(.body-container-market) .theme-panel .checked {
    background-color: #272625 !important;
}
